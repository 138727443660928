<template>
    <div>
        <el-switch v-if='!userhasapikeypreferences'
            style="margin-top: 0.5rem"
            v-model="userhasapikeypreferences"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-text="$t('shop.user_has_key_preference')"
        >
        </el-switch>


        <div class="mini-separator"></div>

        <div v-if='userhasapikeypreferences'>
          <br>
          <span>{{ $t('shop.info_key') }} <el-link href="https://woocommerce.com/document/woocommerce-rest-api/" target="_blank" type="primary">link</el-link></span>
          <div class="mini-separator"></div>
          <span>{{ $t('shop.info_keytutorial') }} <el-link href="https://www.youtube.com/embed/CFkvfJV8Ps0" target="_blank" type="primary">link</el-link></span>
          <div class="mini-separator"></div>
        </div>

        <div v-if="rules.show">
            <p>{{ rules.message }}x</p>
            <ul>
                <li v-for="(rules, index) in rules.requiredResources" :key="index">{{ index }}
                    <span v-for="(rule, indexRule) in rules"
                          :key="indexRule"
                          :class="{'text-green': rule === 'GET', 'text-blue': rule === 'POST', 'text-orange': rule === 'PUT'}">{{ rule }} </span>
                </li>
            </ul>
            <template v-if="Object.keys(rules.additionalResources).length > 0">
                <p>{{ $t('shop.api_additional_resources') }}</p>
                <ul>
                    <li v-for="(rules, index) in rules.additionalResources" :key="index">{{ rules }}</li>
                </ul>
            </template>
        </div>

        <div class="mini-separator" v-if='userhasapikeypreferences'></div>
        <br><el-input :placeholder="$t('shop.name')" v-model="shop.name" v-if='userhasapikeypreferences'></el-input>

        <div class="mini-separator"></div>
        <span>Format: <strong>https://website.ro/</strong></span>
        <el-input :placeholder="$t('shop.site_url')" v-model="shop.shopUrl"></el-input>

        <div v-if='userhasapikeypreferences'>
          <div class="mini-separator"></div>
          <el-input :placeholder="$t('shop.add_api_key')" v-model="shop.api_key"></el-input>

          <div class="mini-separator"></div>
          <el-input :placeholder="$t('shop.add_api_secret')" v-model="shop.api_secret"></el-input>
        </div>

        <template v-if="shop.connect">
            <div class="mini-separator"></div>
            <span>Pentru a activa sincronizarea do comenzi completati datele din tab-ul
                <a href="#" @click.prevent="openSettingsTab" style="color: blue">Settings</a>
            </span>
        </template>

        <div class="mini-separator"></div>
        <template v-if="(!userhasapikeypreferences || shop.name.length > 3 && shop.api_key.length > 2 && shop.api_secret.length > 5) && shop.shopUrl.length > 10">
            <el-button @click="connect" type="primary">{{ $t('shop.check_conn') }}</el-button>
        </template>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        data() {
            return {
                allowwoocomretry: true,
                userhasapikeypreferences: false,
                rules: {
                    show: false,
                    message: '',
                    data: [],
                    additionalResources: {}
                },
                requiredResources: {},
            };
        },
        props: {
            shop: {
                type: Object,
            },
            platform: {
                type: String,
            }
        },
        methods: {
            connect() {
                localStorage.setItem('shopUrl', this.shop.shopUrl);
                this.rules = {
                    show: false,
                    message: '',
                    data: []
                };

                if(!this.userhasapikeypreferences) {
                    if(this.allowwoocomretry) {
                      this.shop.storetoken = uuidv4();
                    } else {
                      this.userhasapikeypreferences = true;
                    }
                } else {
                    this.shop.storetoken = '';
                }

                this.$store.dispatch('integrations/checkConnectionToShop', {
                    ...this.shop,
                    platform: this.platform
                })
                    .then(async (res) => {
                        if (res.error === true) {
                            this.$notify.error(this.$t('shop.conn_err'));
                            if (typeof res.message.requiredResources !== 'undefined') {
                                this.rules = {
                                    show: true,
                                    message: res.message.message,
                                    requiredResources: res.message.requiredResources,
                                    additionalResources: res.message.additionalResources,
                                };
                            }
                        }
                        if (res.error === 0) {

                            if(res?.message?.oauthUrl) {
                              this.$store.dispatch('app/ToggleLoading', true);
                              await new Promise(resolve => {
                                const windowController = window.open(res.message.oauthUrl, '_blank');
                                var timer = setInterval(function() {
                                  if(!windowController || windowController.closed) {
                                    clearInterval(timer);
                                    resolve();
                                  }
                                }, 1000);
                              });

                              this.$store.dispatch('app/ToggleLoading', false);

                              if(this.allowwoocomretry) {
                                this.allowwoocomretry = false;
                                this.connect(true);
                              }

                              return;
                            }

                            this.$router.push({
                                name: 'shopView',
                                params: {
                                    id: res.message.id
                                }
                            }).catch(() => {});
                        }
                    }).catch(() => {
                });
            },
            openSettingsTab() {
                this.activeTab = 'settings';
            }
        }
    };
</script>